import { Controller } from '@hotwired/stimulus';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    connect() {
        this.addAlertToNotes()
    }

    addAlertToNotes() {
        // Sélectionnez tous les éléments <p> dans l'élément cible du contrôleur
        const paragraphs = this.element.querySelectorAll('p');

        paragraphs.forEach(p => {
            const content = p.innerHTML.trim();
            // Utilisez une expression régulière pour vérifier si le contenu commence par l'une des deux variations de "Note"
            const noteRegex = /^\s*<strong>Note\s?:\s?<\/strong>/;
            if (noteRegex.test(content)) {
                // Ajoutez les classes et attributs nécessaires
                p.classList.add('alert', 'alert-primary', 'my-3');
                p.setAttribute('role', 'alert');
            }
        });
    }
}
import { Controller } from '@hotwired/stimulus';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {

    static targets = [ "visites", "visitesUnique", "sessionInconnue", "bots", "blockDevice", 'blockPostsView', 'totalPostsView' ];

    connect() {

        this.doFetch('/admin/ajax/device-block',this.blockDeviceTarget);
        this.doFetch('/admin/ajax/posts-view-block',this.blockPostsViewTarget);

        let boucle = setInterval(() => {
            this.fetchData()
        }, 10000);

    }

    fetchData() {

        fetch('/admin/ajax/dashboard/stats', {
            method: 'GET',
            headers: { "X-Requested-with": "XMLHttpRequest" },
        })
            .then((response) => {
                return response.text();
            })
            .then((retourAjax) => {

                retourAjax = JSON.parse(retourAjax);
                retourAjax = retourAjax['response'];

                if (
                    Number(this.visitesTarget.innerText) !== retourAjax['visites']
                    || Number(this.visitesUniqueTarget.innerText) !== retourAjax['visitesUnique']
                    ||  Number(this.sessionInconnueTarget.innerText) !== retourAjax['sessionInconnue']
                    || Number(this.botsTarget.innerText) !== retourAjax['bots']
                    || Number(this.totalPostsViewTarget.dataset.totalPostsView) !== retourAjax['totalPostsView']
                ) {

                    this.visitesTarget.innerHTML = retourAjax['visites']
                    this.visitesUniqueTarget.innerHTML =  retourAjax['visitesUnique']
                    this.sessionInconnueTarget.innerHTML = retourAjax['sessionInconnue']
                    this.botsTarget.innerHTML = retourAjax['bots']
                    this.doFetch('/admin/ajax/device-block',this.blockDeviceTarget);
                    this.doFetch('/admin/ajax/posts-view-block',this.blockPostsViewTarget);
                }
            });

        return true;
    }


    doFetch(url,el) {

        fetch(url, {
            method: 'GET',
            headers: { "X-Requested-with": "XMLHttpRequest" },
        })
            .then((response) => {
                return response.text();
            })
            .then((retourAjax) => {

                el.innerHTML = retourAjax;

            });

    }
}
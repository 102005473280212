import { Controller } from '@hotwired/stimulus';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    connect() {
        const container = this.element;
        const summary = document.querySelector('#summary');
        const summaryLg = document.querySelector('#summaryLg');
        // Sélectionnez tous les h1 et h4 à l'intérieur du conteneur
        const headers = container.querySelectorAll('h4');

        this.completeSummary(container, summary, headers);

        this.completeSummary(container, summaryLg, headers)

    }

    completeSummary(container, summary, headers) {

        headers.forEach((header, index) => {
            // Ajoutez un ID unique à chaque en-tête pour le ciblage
            const id = `header-link-${index}`;
            header.id = id;

            // Créez un élément de liste
            const li = document.createElement('li');
            li.className = 'list-group-item border-bottom-0'; // Ajoutez les classes

            // Si c'est le premier élément de la boucle, ajoutez la classe "pt-2"
            if (index === 0) {
                li.classList.add('pt-3');
            }

            // Si c'est le dernier élément de la boucle, ajoutez la classe "pb-2"
            if (index === headers.length - 1) {
                li.classList.add('pb-3');
            }
            // Ajoutez le numéro et le point en dehors du lien
            li.textContent = `${index + 1}. `;

            // Créez un lien vers l'en-tête
            const a = document.createElement('a');
            a.href = `#${id}`;
            a.textContent = header.textContent;
            li.appendChild(a);

            summary.appendChild(li)

        });
    }
}
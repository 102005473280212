import { Controller } from '@hotwired/stimulus';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {

    static targets = ['refuser' , 'valider'];
    connect() {

        this.refuserTarget.addEventListener('click', () => {

            this.refuse(this.element.id);

            this.element.classList.add("d-none");

        })

        this.validerTarget.addEventListener('click', () => {

            this.valide(this.element.id);

            this.element.classList.add("d-none");

        })

    }

    refuse(id) {
        const formData = {
            'id' : id,
        }
        const countBadge = document.querySelector('#commentCount');
        fetch('/admin/comment/refuse', {
        method: 'POST',
        headers: { "X-Requested-with": "XMLHttpRequest" },
        body: JSON.stringify(formData),
        })
        .then(function(response) {
            return response.text();
        })
            .then(function(retourAjax) {
                let res = JSON.parse(retourAjax);
                if (res['badge'] > 0 ) {
                    countBadge.classList.remove('d-none')
                    countBadge.innerHTML = res['badge']+"<span class=\"visually-hidden\">New alerts</span>"
                } else {
                    countBadge.classList.add('d-none')
                }
                console.log(res);
            });

    }
    valide(id) {
        const formData = {
            'id' : id,
        }
        const countBadge = document.querySelector('#commentCount');

        fetch('/admin/comment/valide', {
        method: 'POST',
        headers: { "X-Requested-with": "XMLHttpRequest" },
        body: JSON.stringify(formData),
        })
        .then(function(response) {
            return response.text();
        })
            .then(function(retourAjax) {
                let res = JSON.parse(retourAjax);
                if (res['badge'] > 0 ) {
                    countBadge.classList.remove('d-none')
                    countBadge.innerHTML = res['badge']+"<span class=\"visually-hidden\">New alerts</span>"
                } else {
                    countBadge.classList.add('d-none')
                }
                console.log(res);
            });

    }

}
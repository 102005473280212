import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';

export default class extends Controller {

    static targets = ['blockSortable'];
    connect() {

        let el = this.blockSortableTarget;

        Sortable.create(el, {
            animation: 150,

            onStart: function (evt) {
            },

            onEnd: function (evt) {
            },

            onUpdate: function (evt) {
                // l'élément déplacé
                const itemEl = evt.item;
                const newPosition = evt.newIndex
                const categoryId = itemEl.id

                const formData = {
                    newPosition : newPosition,
                    categoryId : categoryId
                }

                fetch('/admin/category/ajax/update-category-position', {
                    method: 'POST',
                    headers: { "X-Requested-with": "XMLHttpRequest" },
                    body: JSON.stringify(formData),
                })
                .then(function(response) {
                    return response.text();
                })
                .then(function(retourAjax) {
                    console.log(retourAjax);
                });
            },
        });
    }
}

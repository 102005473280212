import { Controller } from '@hotwired/stimulus';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    connect() {
        console.log('hello')

        const deleteModal = document.getElementById('deleteModal')
        if (deleteModal) {
            deleteModal.addEventListener('show.bs.modal', event => {
                // Button that triggered the modal
                const button = event.relatedTarget
                // Extract info from data-bs-* attributes
                const categoryId = button.getAttribute('data-bs-category')
                const deletePath = button.getAttribute('data-bs-path')
                const name  = button.getAttribute('data-bs-name')
                // If necessary, you could initiate an Ajax request here
                // and then do the updating in a callback.

                // Update the modal's content.

                const modalBodyInput = deleteModal.querySelector('.modal-body input')
                const deleteName = deleteModal.querySelector('.delete-name')
                const deleteLink = deleteModal.querySelector('.delete-link')

                deleteName.textContent = name
                deleteLink.href = deletePath
            })
        }
    }


}
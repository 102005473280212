import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

    static targets = ['dropdownToggle'];
    connect() {
        console.log(this.dropdownToggleTargets)

        var dropdownToggles = document.querySelectorAll('.dropdown-toggle');

        dropdownToggles.forEach(function(toggle) {

            toggle.addEventListener('click', function() {
                var dropdown = toggle.nextElementSibling;
                var offsetLeft = toggle.getBoundingClientRect().left;
                var dropdownWidth = dropdown.offsetWidth;
                var windowWidth = window.innerWidth;

                // Si le menu déroulant dépasse à droite
                if (offsetLeft + dropdownWidth > windowWidth) {
                    dropdown.classList.add('dropdown-menu-end');
                } else {
                    dropdown.classList.remove('dropdown-menu-end');
                }
            });

        });
    }
}
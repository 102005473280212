import { Controller } from '@hotwired/stimulus';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    connect() {

        var clearButton = document.querySelector('.clear-button');
        var searchInput = document.querySelector('.search-input');

        if (searchInput.value !== "") {
            clearButton.style.display = "block"
        }

        searchInput.addEventListener('input', function() {
            if (searchInput.value) {
                clearButton.style.display = 'block'; // montre le bouton quand il y a du texte
            } else {
                clearButton.style.display = 'none'; // cache le bouton quand le texte est supprimé
            }
        });

        clearButton.addEventListener('click', function() {
            searchInput.value = '';
            clearButton.style.display = 'none'; // cache le bouton après avoir cliqué dessus
        });
    }
}